import request from '../request'

//仓库列表
export const storeroom_index_request = p => {
    return request({
        method:'GET',
        url:'storeroom/index',
        params: p
    })
}

//获取仓库详情
export const storeroom_details_request = id => {
    return request({
        method:'GET',
        url:'storeroom/details',
        params: {
            id
        }
    })
}

//添加仓库
export const storeroom_add_request = d => {
    return request({
        method:'POST',
        url:'storeroom/add',
        data: d
    })
}

//修改仓库
export const storeroom_edit_request = d => {
    return request({
        method:'PUT',
        url:'storeroom/edit',
        data: d
    })
}

//删除仓库
export const storeroom_del_request = id => {
    return request({
        method:'DELETE',
        url:'storeroom/del',
        data: {
            id: id
        }
    })
}