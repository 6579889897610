<template>
    <div class="StoreroomView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" @show_pp="show_popup = true"></nav-bar-component>
        <van-popup
            v-model="show_popup"
            position="top"
            round
            :close-on-click-overlay="false"
            closeable   
        >
            <van-form :key="form_key">
                <div class="search_title">
                    <van-tag round type="success" size="large">条件搜索区</van-tag>
                </div>
                <van-field
                    v-model="cond.name"
                    name="name"
                    label="仓库名称"
                    placeholder="仓库名称"
                />
                <van-field
                    readonly
                    clickable
                    name="enterprise"
                    :value="cond.enterprise_name"
                    label="所属企业"
                    placeholder="点击选择企业"
                    @click="show_enterprise_picker = true"
                />
                <van-popup v-model="show_enterprise_picker" position="bottom">
                <van-picker
                    value-key="name"
                    show-toolbar
                    :columns="all_enterprise"
                    @confirm="confirm_enterprise"
                    @cancel="show_enterprise_picker = false"
                />
                </van-popup>
                <div class="search_button">
                    <van-button round block type="info" icon="search" @click="search">搜 索</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="warning" @click="reset">重 置</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="default" @click="show_popup = false">取 消</van-button>
                </div>
            </van-form>
        </van-popup>
        <div class="insert">
            <van-button v-if="this.$_has('新增仓库')" icon="add-o" type="primary" round plain size="small" @click="show_add">添加新仓库</van-button>
        </div>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.name}}</span>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="包含库区" :value="v.region" :border="false" />
                <van-cell class="note_cell" title="所属企业" :value="v.enterprise_name" :border="false" />
                <van-cell class="note_cell" title="负责人" :value="v.person_name" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { storeroom_index_request } from '@/network/WareHouse/storeroom'
import { enterprise_list_request } from '@/network/list.js'

export default {
    name:'StoreroomView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: '搜索'
                },
            },
            my_nav_bar_name: '仓库管理',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            all_enterprise: [],
            show_popup: false,
            form_key: Date.now(),
            cond: {
                name: '',
                enterprise_id: '',
                enterprise_name: '',
                page: 1
            },
            show_enterprise_picker: false
        }
    },
    computed:{},
    methods:{
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_storeroom() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            storeroom_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_enterprise(e) {
            this.cond.enterprise_id = e.id
            this.cond.enterprise_name = e.name
            this.show_enterprise_picker = false
        },
        search() {
            this.cond.page = 1
            this.get_storeroom()
            this.show_popup = false
        },
        reset() {
            this.cond = {
                name: '',
                enterprise_id: '',
                enterprise_name: '',
                page: 1
            }
            this.form_key = Date.now()
        },
        show_details(v) {
            if(this.$_has('仓库详情')) {
                this.$router.push({
                    name: 'StoreroomDetails',
                    query: {
                        id: v.id
                    }
                })
            }
        },
        show_add() {
            this.$router.push({
                name: 'StoreroomDetails',
                query: {
                    id: 0
                }
            })
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                storeroom_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_storeroom()
        this.get_all_enterprise()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent
    },
    watch:{}
}
</script>

<style>
.StoreroomView .van-cell-group {
    margin-bottom: 0.2rem;
}
.StoreroomView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.StoreroomView .note_cell {
    padding: 0.1rem 0.42667rem
}
.StoreroomView .search_title {
    text-align: center;
}
.StoreroomView .search_button {
    margin: 0.5rem;
}
.StoreroomView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>